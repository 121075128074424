/*---------------------------------------------------------------------------------------------
*
*  Admin Navigation
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-toolbar {
	display: flex;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
	position: unset;
	top: 32px;
	height: 72px;
	z-index: 800;
	background: $gray-700;
	color: $gray-400;

	@media screen and (max-width: $md) {
		position: static;
	}

	.acf-logo {
		margin: {
			right: 32px;
		};

		img {
			display: block;
			max-width: 55px;
			line-height: 0%;
		}

	}

	h2 {
		display: none;
		color: $gray-50;
	}

	.acf-tab {
		display: flex;
		align-items: center;
		box-sizing: border-box;
		min-height: 40px;
		margin: {
			right: 8px;
		};
		padding: {
			top: 8px;
			right: 16px;
			bottom: 8px;
			left: 16px;
		};
		border: {
			width: 1px;
			style: solid;
			color: transparent;
		};
		border-radius: $radius-md;
		@extend .p4;
		color: $gray-400;
		text-decoration: none;

		&.is-active {
			background-color: $gray-600;
			color: #fff;
		}
		&:hover {
			background-color: $gray-600;
			color: $gray-50;
		}
		&:focus-visible {
			border: {
				width: 1px;
				style: solid;
				color: $gray-500;
			};
		}
		&:focus {
			box-shadow: none;
		}
	}

	// Within wpcontent.
	#wpcontent & {
		box-sizing: border-box;
		margin-left: -20px;
		padding: {
			top: 16px;
			right: 32px;
			bottom: 16px;
			left: 32px;
		};
	}

	// Mobile
	@media screen and (max-width: 600px) {
		& {
			display: none;
		}
	}
}

.rtl {
	#wpcontent .acf-admin-toolbar {
		margin-left: 0;
		margin-right: -20px;

		.acf-tab {
			margin: {
				left: 8px;
				right: 0;
			};
		}
	}

	.acf-logo {
		margin: {
			right: 0;
			left: 32px;
		};
	}
}

/*---------------------------------------------------------------------------------------------
*
*  Admin Toolbar Icons
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-toolbar {

	.acf-tab {

		i.acf-icon {
			display: none; // Icons only shown for specified nav items below
			margin: {
				right: 8px;
				left: -2px;
			};
		}

		// Only show icons for specified nav items, stops third party plugin items with no icon appearing broken
		&.acf-header-tab-acf-field-group,
		&.acf-header-tab-acf-tools,
		&.acf-header-tab-acf-settings-updates {
			i.acf-icon {
				display: inline-flex;
			}
		}

		&.is-active,
		&:hover {

			i.acf-icon {
				background-color: $gray-200;
			}

		}

	}

	.rtl & .acf-tab {
		i.acf-icon {
			margin: {
				right: -2px;
				left: 8px;
			};
		}
	}

	// Field groups tab
	.acf-header-tab-acf-field-group {

		i.acf-icon {
			$icon-url: url('../../images/icons/icon-field-groups.svg');
			-webkit-mask-image: $icon-url;
			mask-image: $icon-url;
		}

	}

	// Field groups tab
	.acf-header-tab-acf-tools {

		i.acf-icon {
			$icon-url: url('../../images/icons/icon-tools.svg');
			-webkit-mask-image: $icon-url;
			mask-image: $icon-url;
		}

	}

	// Field groups tab
	.acf-header-tab-acf-settings-updates {

		i.acf-icon {
			$icon-url: url('../../images/icons/icon-updates.svg');
			-webkit-mask-image: $icon-url;
			mask-image: $icon-url;
		}

	}


}