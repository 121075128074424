/*--------------------------------------------------------------------------------------------
*
*	Header upsell button
*
*--------------------------------------------------------------------------------------------*/
.acf-admin-toolbar {

	a.acf-admin-toolbar-upgrade-btn {
		display: inline-flex;
		align-items: center;
		align-self: stretch;
		padding: {
			top: 0;
			right: 16px;
			bottom: 0;
			left: 16px;
		};
		background: $gradient-pro;
		background-size: 180% 80%;
		background-position: 100% 0;
		transition: background-position .5s;
		border-radius: $radius-md;
		text-decoration: none;

		@media screen and (max-width: 768px) {
			display: none;
		}

		&:hover {
			background-position: 0 0;
		}

		&:focus {
			border: none;
			outline: none;
			box-shadow: none;
		}

		p {
			margin: 0;
			padding: {
				top: 8px;
				bottom: 8px;
			}
			@extend .p4;
			font-weight: normal;
			text-transform: none;
			color: #fff;
		}

		.acf-icon {
			$icon-size: 18px;
			width: $icon-size;
			height: $icon-size;
			margin: {
				right: 6px;
				left: -2px;
			};
			background-color: $gray-50;
		}

	}

}

/*--------------------------------------------------------------------------------------------
*
*  Upsell block
*
*--------------------------------------------------------------------------------------------*/
#acf-field-group-pro-features {

	.acf-field-group-pro-features-wrapper {
		display: flex;
		justify-content: flex-start;
		align-content: stretch;
		align-items: center;

		@media screen and (max-width: 768px) {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-content: flex-start;
			align-items: flex-start;

			.acf-field-group-pro-features-content,
			.acf-field-group-pro-features-actions {
				flex: 0 1 100%;
			}

		}

		.acf-field-group-pro-features-content {
			flex: 1 1 auto;
			margin: {
				right: 40px;
			};

			@media screen and (max-width: 768px) {
				margin: {
					right: 0;
					bottom: 8px;
				};
			}

		}

		.acf-field-group-pro-features-actions {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			min-width: 160px;

			@media screen and (max-width: 768px) {
				justify-content: flex-start;
			}

		}

	}

	&.postbox {
		display: flex;
		align-items: center;
		min-height: 120px;
		background-image: linear-gradient(to right, #1d4373, #24437e, #304288, #413f8f, #543a95);
		color: $gray-200;

		.postbox-header {
			display: none;
		}

		.inside {
			width: 100%;
			border: none;
		}

	}

	h1 {
		margin: {
			top: 0;
			bottom: 4px;
		};
		padding: {
			top: 0;
			bottom: 0;
		};
		@extend .p1;
		font-weight: bold;
		color: $gray-50;

		.acf-icon {
			margin: {
				right: 8px;
			};
		}

	}

	// Upsell block btn
	.acf-btn {
		display: inline-flex;
		background-color: rgba(#fff,.2);
		border: none;

		&:hover {
			background-color: rgba(#fff,.3);
		}

		.acf-icon {
			margin: {
				right: -2px;
				left: 8px;
			};
		}

	}

	// Features list
	.acf-pro-features-list  {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		margin: {
			top: 16px;
			bottom: 0;
		};

		@media screen and (max-width: 768px) {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-content: flex-start;
			align-items: flex-start;
		}

		li {
			display: flex;
			box-sizing: border-box;
			margin: {
				right: 32px;
				bottom: 6px;
			};

			@media screen and (max-width: 880px) {
				flex: 0 1 calc(33.3% - 32px);
			}

			@media screen and (max-width: $sm) {
				flex: 0 1 100%;
			}

			&:last-child {
				margin: {
					right: 0;
				};
			}

			&:before {
				content: '';
				$icon-size: 16px;
				display: inline-block;
				width: $icon-size;
				height: $icon-size;
				margin: {
					right: 8px;
				};
				background-color: $color-success;
				border: none;
				border-radius: 0;
				-webkit-mask-size: contain;
				mask-size: contain;
				-webkit-mask-repeat: no-repeat;
				mask-repeat: no-repeat;
				-webkit-mask-position: center;
				mask-position: center;
				-webkit-mask-image: url('../../images/icons/icon-check-circle-solid.svg');
				mask-image: url('../../images/icons/icon-check-circle-solid.svg');
			}

		}

	}

}

